interface EnvConfig {
  API_URL: string;
  API_KEY: string;
  PLATFORM_FEE_ADDRESS: string;
  PLATFORM_FEE_PERCENTAGE: number;
  NETWORK_FEE: number;
  LISTINGS_PER_PAGE: number;
}

class Environment {
  private config: EnvConfig;

  constructor() {
    this.config = {
      API_URL: process.env.NEXT_PUBLIC_API_URL || 'https://burtswap-backend.vercel.app/api',
      API_KEY: process.env.NEXT_PUBLIC_API_KEY || 'your_secret_key_123_burt_key_123',
      PLATFORM_FEE_ADDRESS: process.env.NEXT_PUBLIC_PLATFORM_FEE_ADDRESS || 
        'kaspa:qztft5y68khgra90rrph64wxnxnd29fejehqyra4y6s3zr84h58uu3920paeu',
      PLATFORM_FEE_PERCENTAGE: Number(process.env.NEXT_PUBLIC_PLATFORM_FEE_PERCENTAGE) || 0.99,
      NETWORK_FEE: Number(process.env.NEXT_PUBLIC_NETWORK_FEE) || 0.1,
      LISTINGS_PER_PAGE: Number(process.env.NEXT_PUBLIC_LISTINGS_PER_PAGE) || 5,
    };
  }

  validate() {
    const requiredVars = ['API_URL', 'API_KEY'];
    const missing = requiredVars.filter(key => !this.config[key as keyof EnvConfig]);
    
    if (missing.length > 0) {
      console.warn(`Missing environment variables: ${missing.join(', ')}`);
    }
  }

  getConfig(): EnvConfig {
    this.validate();
    return this.config;
  }
}

const environment = new Environment();
export const getEnvironment = () => environment.getConfig();