export interface Advertisement {
  id: number;
  title: string;
  description: string;
  ctaText: string;
  ctaUrl: string;
  gradient: string;
  showVideo?: boolean;
  videoUrl?: string;
  showLogo?: boolean;
  logo?: {
    url: string;
    type: 'svg' | 'webp' | 'png' | 'jpg' | 'jpeg';
    alt?: string;
  };
  showSocials?: boolean;
  socials?: {
    telegram?: string;
    twitter?: string;
  };
}

export const advertisements: Advertisement[] = [
  {
    id: 1,
    title: "ADVERTISE",
    description: "DM us for more info",
    ctaText: "Advertise",
    ctaUrl: "https://www.burt.world",
    gradient: "from-red-500 to-blue-500",
    showVideo: false,
    videoUrl: "",
    showSocials: true,
    showLogo: true,
    logo: {
      url: "burt2.png",
      type: "png",
      alt: "KRC20 Logo"
    },
    socials: {
      telegram: "https://x.com/Burtonkas_",
      twitter: "https://x.com/Burtonkas_"
    }
  },
];