import React from 'react';
import { Card } from "../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Globe, ArrowUpRight, Twitter, MessageCircle } from 'lucide-react';

const MODIFIED_URLS: { [key: string]: string } = {
  'Biconomy': 'https://www.biconomy.com/sign-up?r_user_id=W9RNIX0J8',
  'KSPR Bot': 'https://t.me/kspr_home_bot',
  'XT': 'https://www.xt.com/en/accounts/register',
  'Kaspiano': 'https://www.kaspiano.com/',
  'GuacSwap': 'https://swap.guac.fyi/',
  'Kaspa Market': 'https://kaspamarket.io/',
  'Coinstore': 'https://h5.coinstore.com/h5/signup?invitCode=OygYIL',
  'CoinEx': 'https://www.coinex.com/register?refer_code=p7vts',
  'Chainge': 'https://dapp.chainge.finance/?fromChain=KAS&toChain=KAS&fromToken=USDT&toToken=NACHO&channel=burtsworld',
  'MEXC': 'https://www.mexc.com/register?inviteCode=12TvMq',
  'GroveX': 'https://www.grovex.io/',
  'Pionex': 'https://www.pionex.com/en/signUp?r=0uDSn7pw7pk',
  'Ascendex': 'https://ascendex.com/en-us/register?inviteCode=UQWCYR1CW',
  'BitMart': 'https://www.bitmart.com/invite/cjA4Gq/en',
  'FameEX': 'https://www.fameex.com/en-US/',
  'LBank': 'https://www.lbank.com/en-US/login/?icode=4FSp0'
};

interface TokenInfoProps {
  ticker: string;
  price: {
    floorPrice: number;
    marketCapInUsd: number;
    change24h: number;
  };
  socialLinks: Array<{
    type: string;
    url: string;
  }>;
  marketsData: Array<{
    name: string;
    marketData: {
      priceInUsd: number;
      volumeInUsd: number;
    };
    metadata: {
      name: string;
      iconUrl: string;
      url: string;
    };
  }>;
  holderTotal: number;
  transferTotal: number;
  mintTotal: number;
  onHolderClick: () => void;
}

export const TokenInfo: React.FC<TokenInfoProps> = ({
  ticker,
  price,
  socialLinks,
  marketsData,
  holderTotal,
  transferTotal,
  mintTotal,
  onHolderClick
}) => {
  const formatPrice = (price: number | undefined) => {
    if (!price) return '$0.00';
    if (price < 0.00001) return `$${price.toExponential(4)}`;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 6,
      maximumFractionDigits: 6
    }).format(price);
  };

  const formatNumber = (num: number | undefined) => {
    if (!num) return '$0';
    if (num >= 1e9) return `$${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `$${(num / 1e6).toFixed(2)}M`;
    if (num >= 1e3) return `$${(num / 1e3).toFixed(2)}K`;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2
    }).format(num);
  };

  const modifiedMarketsData = marketsData.map(market => ({
    ...market,
    metadata: {
      ...market.metadata,
      url: MODIFIED_URLS[market.name] || market.metadata.url
    }
  }));

  return (
    <div className="space-y-3">
      <Card className="p-2">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-transparent">
              <TableHead className="w-[200px]">Exchange</TableHead>
              <TableHead>Price</TableHead>
              <TableHead className="text-right">Volume (24h)</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {modifiedMarketsData.map((market, index) => (
              <TableRow
                key={index}
                className="cursor-pointer hover:bg-accent/50 transition-colors"
                onClick={() => window.open(market.metadata.url, '_blank')}
              >
                <TableCell className="font-medium">
                  <div className="flex items-center gap-2">
                    <img
                      src={market.metadata.iconUrl}
                      alt={market.name}
                      className="w-5 h-5 rounded-full"
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = '/token-icon.svg';
                      }}
                    />
                    {market.name}
                  </div>
                </TableCell>
                <TableCell className="font-medium">
                  {formatPrice(market.marketData.priceInUsd)}
                </TableCell>
                <TableCell className="text-right font-medium">
                  {formatNumber(market.marketData.volumeInUsd)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      <Card className="p-2">
        <div className="flex flex-wrap gap-2">
          {socialLinks?.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 p-2 hover:bg-accent rounded-lg transition-colors text-sm"
            >
              {link.type === 'twitter' && <Twitter className="h-4 w-4" />}
              {link.type === 'telegram' && <MessageCircle className="h-4 w-4" />}
              {link.type === 'website' && <Globe className="h-4 w-4" />}
              <span className="capitalize">{link.type}</span>
              <ArrowUpRight className="h-4 w-4" />
            </a>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default TokenInfo;