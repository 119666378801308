import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "../components/ui/card";
import { Alert, AlertDescription } from "../components/ui/alert";
import { Button } from '../components/ui/button';
import { Loader2, ArrowRight } from "lucide-react";
import TVChart from '../components/Chart';
import ChangeSwap from '../components/ChangeSwap';
import { TokenInfo } from '../components/TokenInfoSidebar';
import { ChartHeader } from '../components/ChartHeader';
import { Order } from 'src/types';
import { OrdersAPI } from 'src/services/api';

export interface TokenData {
  ticker: string;
  iconUrl?: string;
  price: {
    floorPrice: number;
    marketCapInUsd: number;
    change24h: number;
    priceInUsd: number;
  };
  tradeVolume: {
    amountInUsd: number;
  };
  priceHistory: Array<{
    p: number;
    t: number;
  }>;
  holderTotal: number;
  transferTotal: number;
  mintTotal: number;
  socialLinks: Array<{
    type: string;
    url: string;
  }>;
  marketsData: Array<{
    name: string;
    marketData: {
      priceInUsd: number;
      volumeInUsd: number;
    };
    metadata: {
      name: string;
      iconUrl: string;
      url: string;
    };
  }>;
}

const defaultTokenData: TokenData = {
  ticker: '',
  iconUrl: undefined,
  price: {
    floorPrice: 0,
    marketCapInUsd: 0,
    change24h: 0,
    priceInUsd: 0
  },
  tradeVolume: {
    amountInUsd: 0
  },
  priceHistory: [],
  holderTotal: 0,
  transferTotal: 0,
  mintTotal: 0,
  socialLinks: [],
  marketsData: []
};

interface ChartsPageProps {
  tokenData: TokenData;
  searchedToken?: string;
  setCurrentPage: (page: string) => void;
  setSearchedToken: (token: string) => void;
  connected: boolean;
  userAddress?: string;
  handleConnect: () => Promise<void>;
}

const sompiToKAS = (sompi: number): number => sompi / 1e8;

const formatNumber = (value: number | string | undefined, minDP: number = 2): string => {
  if (!value) return '0';
  const num = Number(value);
  if (isNaN(num)) return '0';

  const absNum = Math.abs(num);
  let maxDP = 2;

  if (absNum > 0 && absNum < 1) {
    let decimal = absNum;
    let decimalPlaces = 0;
    while (decimal < 1 && decimalPlaces < 10) {
      decimal *= 10;
      decimalPlaces++;
    }
    maxDP = decimalPlaces + 2;
  } else if (absNum >= 1000) {
    maxDP = 2;
  }

  return num.toLocaleString(undefined, {
    minimumFractionDigits: minDP,
    maximumFractionDigits: Math.max(maxDP, minDP)
  });
};

export function ChartsPage({
  tokenData,
  searchedToken,
  setCurrentPage,
  setSearchedToken,
  connected,
  userAddress,
  handleConnect
}: ChartsPageProps) {
  const [currentTokenData, setCurrentTokenData] = useState<TokenData>(tokenData || defaultTokenData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [chaingeTokens, setChaingeTokens] = useState<any[]>([]);
  const [isTokenTradeable, setIsTokenTradeable] = useState(false);

  useEffect(() => {
    if (!searchedToken) return;

    const fetchTokenData = async (token: string): Promise<Response> => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout
    
      try {
        const response = await fetch(`https://api-v2-do.kas.fyi/token/krc20/${token}/info`, {
          signal: controller.signal
        });
        clearTimeout(timeoutId);
        return response;
      } catch (error) {
        clearTimeout(timeoutId);
        throw error;
      }
    };
    
    const fetchWithRetry = async (token: string, maxRetries = 3): Promise<TokenData> => {
      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          const response = await fetchTokenData(token);
          
          // Handle different status codes
          if (response.status === 404) {
            throw new Error(`Token ${token} not found`);
          }
          
          if (response.status === 500) {
            // If it's the last attempt, throw the error
            if (attempt === maxRetries - 1) {
              throw new Error(`Server error while fetching ${token}`);
            }
            // Otherwise, continue to retry
            await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
            continue;
          }
    
          if (!response.ok) {
            throw new Error(`Unexpected response: ${response.status}`);
          }
    
          const data = await response.json();
    
          // Validate the response data
          if (!data || typeof data !== 'object') {
            throw new Error('Invalid response format');
          }
    
          // Return properly validated data
          return {
            ticker: data.ticker || token,
            iconUrl: data.iconUrl,
            price: {
              floorPrice: data.price?.floorPrice ?? 0,
              marketCapInUsd: data.price?.marketCapInUsd ?? 0,
              change24h: data.price?.change24h ?? 0,
              priceInUsd: data.price?.priceInUsd ?? 0
            },
            tradeVolume: {
              amountInUsd: data.tradeVolume?.amountInUsd ?? 0
            },
            priceHistory: Array.isArray(data.priceHistory) ? data.priceHistory : [],
            holderTotal: typeof data.holderTotal === 'number' ? data.holderTotal : 0,
            transferTotal: typeof data.transferTotal === 'number' ? data.transferTotal : 0,
            mintTotal: typeof data.mintTotal === 'number' ? data.mintTotal : 0,
            socialLinks: Array.isArray(data.socialLinks) ? data.socialLinks : [],
            marketsData: Array.isArray(data.marketsData) ? data.marketsData.map((market: { name: any; marketData: { priceInUsd: any; volumeInUsd: any; }; metadata: { name: any; iconUrl: any; url: any; }; }) => ({
              name: market.name || '',
              marketData: {
                priceInUsd: market.marketData?.priceInUsd ?? 0,
                volumeInUsd: market.marketData?.volumeInUsd ?? 0
              },
              metadata: {
                name: market.metadata?.name || market.name || '',
                iconUrl: market.metadata?.iconUrl || '',
                url: market.metadata?.url || ''
              }
            })) : []
          };
    
        } catch (error) {
          if (attempt === maxRetries - 1) {
            throw error;
          }
          // Exponential backoff
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
        }
      }
      throw new Error('Max retries reached');
    };
    
    // Update the fetchData function in your component:
    const fetchData = async () => {
      setLoading(true);
      setError(null);
    
      try {
        const data = await fetchWithRetry(searchedToken);
        setCurrentTokenData(data);
      } catch (error) {
        console.error('Error fetching token data:', error);
        const errorMessage = error instanceof Error ? error.message : 'Failed to fetch token data';
        setError(errorMessage);
        setCurrentTokenData(defaultTokenData);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchedToken]);

  useEffect(() => {
    if (!searchedToken) return;

    const fetchChaingeData = async () => {
      try {
        const response = await fetch('https://api2.chainge.finance/v1/getAssetsByChain?chain=KAS');
        const data = await response.json();
        if (data.code === 0) {
          const tokens = data.data.list;
          setChaingeTokens(tokens);
          setIsTokenTradeable(tokens.some((token: { symbol: string; krc20Tradeable: boolean }) =>
            token.symbol === searchedToken && token.krc20Tradeable
          ));
        }
      } catch (error) {
        console.error('Error fetching Chainge data:', error);
      }
    };

    fetchChaingeData();
  }, [searchedToken]);

  const handleHolderClick = () => {
    if (searchedToken) {
      setSearchedToken(searchedToken);
      setCurrentPage('holders');
    }
  };

  const TokenIcon = ({ ticker, tokenData, size = "w-4 h-4" }: { ticker: string; tokenData?: any; size?: string }) => {
    const [error, setError] = useState(false);

    if (ticker.toLowerCase() === 'kas') {
      return (
        <div className={`${size} rounded-full overflow-hidden flex items-center justify-center bg-muted`}>
          <img src="/kas.png" alt="KAS" className="w-full h-full object-cover" />
        </div>
      );
    }

    if (ticker.toLowerCase() === 'usd') {
      return (
        <div className={`${size} rounded-full overflow-hidden flex items-center justify-center bg-muted`}>
          <img src="/USD.png" alt="USD" className="w-full h-full object-cover" />
        </div>
      );
    }

    return (
      <div className={`${size} rounded-full overflow-hidden flex items-center justify-center bg-muted`}>
        {!error && tokenData?.iconUrl ? (
          <img
            src={tokenData.iconUrl}
            alt={ticker}
            className="w-full h-full object-cover"
            onError={() => setError(true)}
          />
        ) : (
          <div className="text-xs font-medium">{ticker.slice(0, 2)}</div>
        )}
      </div>
    );
  };

  const BestOrder = ({
    searchedToken,
    setCurrentPage
  }: {
    searchedToken: string;
    setCurrentPage: (page: string) => void;
  }) => {
    const [bestOrder, setBestOrder] = useState<Order | null>(null);
    const [loading, setLoading] = useState(false);
    const [kasPrice, setKasPrice] = useState<number>(0);

    useEffect(() => {
      const fetchKasPrice = async () => {
        try {
          const response = await fetch('https://api.kaspa.org/info/price?stringOnly=true');
          const priceString = await response.text();
          setKasPrice(Number(priceString));
        } catch (error) {
          console.error('Error fetching KAS price:', error);
          setKasPrice(0);
        }
      };

      fetchKasPrice();
      const interval = setInterval(fetchKasPrice, 60000);
      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      const fetchBestOrder = async () => {
        if (!searchedToken) return;
        setLoading(true);
        try {
          const data = await OrdersAPI.fetchOrders(searchedToken);
          if (data.length > 0) {
            const cheapest = data.sort((a, b) => {
              const priceA = sompiToKAS(a.uAmt) / sompiToKAS(a.amount);
              const priceB = sompiToKAS(b.uAmt) / sompiToKAS(b.amount);
              return priceA - priceB;
            })[0];
            setBestOrder(cheapest);
          } else {
            setBestOrder(null);
          }
        } catch (error) {
          console.error('Error fetching orders:', error);
          setBestOrder(null);
        } finally {
          setLoading(false);
        }
      };

      fetchBestOrder();
    }, [searchedToken]);

    const handleTradeClick = () => {
      sessionStorage.setItem('burtswap_initial_ticker', searchedToken);
      setCurrentPage('marketplace');
    };

    if (loading) {
      return <div className="flex justify-center">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>;
    }

    if (!bestOrder) {
      return (
        <Alert>
          <AlertDescription>No active orders for {searchedToken}</AlertDescription>
        </Alert>
      );
    }

    const pricePerToken = sompiToKAS(bestOrder.uAmt) / sompiToKAS(bestOrder.amount);
    const pricePerTokenUSD = pricePerToken * kasPrice;
    const totalKAS = sompiToKAS(bestOrder.uAmt);
    const totalUSD = totalKAS * kasPrice;

    return (
      <div className="space-y-1">
        <div className="grid grid-cols-2 gap-2 text-sm">
          <div className="text-muted-foreground">Best Price (KAS):</div>
          <div className="text-right flex items-center justify-end gap-1 font-medium">
            {formatNumber(pricePerToken)}
            <TokenIcon ticker="KAS" size="w-4 h-4" />
          </div>
          <div className="text-muted-foreground">Best Price (USD):</div>
          <div className="text-right flex items-center justify-end gap-1 font-medium">
            ${formatNumber(pricePerTokenUSD)}
            <TokenIcon ticker="USD" size="w-4 h-4" />
          </div>
          <div className="text-muted-foreground">Token:</div>
          <div className="text-right flex items-center justify-end gap-1 font-medium">
            {formatNumber(sompiToKAS(bestOrder.amount))}
            <TokenIcon ticker={bestOrder.ticker} tokenData={currentTokenData} size="w-4 h-4" />
          </div>
          <div className="text-muted-foreground">Total (KAS):</div>
          <div className="text-right flex items-center justify-end gap-1 font-medium">
            {formatNumber(totalKAS)}
            <TokenIcon ticker="KAS" size="w-4 h-4" />
          </div>
          <div className="text-muted-foreground">Total (USD):</div>
          <div className="text-right flex items-center justify-end gap-1 font-medium">
            ${formatNumber(totalUSD)}
            <TokenIcon ticker="USD" size="w-4 h-4" />
          </div>
        </div>
        <Button className="w-full" onClick={handleTradeClick}>
          Trade on KasSwap <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </div>
    );
  };

  if (!searchedToken) {
    return (
      <div className="h-[calc(100vh-6rem)] w-full overflow-hidden flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-2">No Token Selected</h2>
          <p className="text-muted-foreground">Search for a token or click one from the token list to view its chart</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-[calc(100vh-8rem)] w-full overflow-hidden flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="h-8 w-8 animate-spin" />
          <p className="text-muted-foreground">Loading token data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[calc(100vh-8rem)] w-full overflow-hidden flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-destructive mb-2">Error</h2>
          <p className="text-muted-foreground">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full px-1 space-y-1">
      <ChartHeader
        ticker={currentTokenData.ticker}
        iconUrl={currentTokenData.iconUrl}
        price={currentTokenData.price}
        marketsData={currentTokenData.marketsData}
        holderTotal={currentTokenData.holderTotal}
        transferTotal={currentTokenData.transferTotal}
        onHolderClick={handleHolderClick}
      />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-1">
        <div className="hidden lg:block lg:col-span-3">
          <Card className="h-[650px]">
            <CardContent className="px-5 py-1 h-full flex flex-col">
              <div className="flex-1">
                {isTokenTradeable ? (
                  <ChangeSwap
                    connected={connected}
                    walletAddress={userAddress}
                    handleConnect={handleConnect}
                    fixedToken={searchedToken}
                  />
                ) : (
                  <Alert>
                    <AlertDescription>{searchedToken} is not available on Chainge Finance</AlertDescription>
                  </Alert>
                  )}
                  </div>
    
                  <div className="border-t pt-1 mt-1">
                    <h3 className="text-lg font-semibold mb-4">Kas Swap</h3>
                    <BestOrder
                      searchedToken={searchedToken}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="col-span-1 lg:col-span-9">
              <Card className="h-[650px]">
                <CardContent className="p-0 h-full">
                  <TVChart
                    containerId="tv_chart_container"
                    ticker={searchedToken}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          <TokenInfo
            ticker={currentTokenData.ticker}
            price={currentTokenData.price}
            socialLinks={currentTokenData.socialLinks}
            marketsData={currentTokenData.marketsData}
            holderTotal={currentTokenData.holderTotal}
            transferTotal={currentTokenData.transferTotal}
            mintTotal={currentTokenData.mintTotal}
            onHolderClick={handleHolderClick}
          />
        </div>
      );
    }