import { Order, TradeHistory } from '../types';
import { getEnvironment } from '../config/env';

interface APIError {
  error: string;
  details?: { msg: string; param: string }[];
}

interface APIResponse<T> {
  data: T;
  pagination?: {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    itemsPerPage: number;
  };
}

const env = getEnvironment();

const getHeaders = () => ({
  'Content-Type': 'application/json',
  'x-api-key': env.API_KEY,
});

export const OrdersAPI = {
  async fetchOrders(ticker: string): Promise<Order[]> {
    try {
      if (!ticker.trim()) {
        return [];
      }

      const response = await fetch(
        `${env.API_URL}/orders?ticker=${encodeURIComponent(ticker.trim().toUpperCase())}`,
        { headers: getHeaders() }
      );

      if (!response.ok) {
        const errorData: APIError = await response.json().catch(() => ({
          error: response.statusText
        }));

        if (errorData.details) {
          const errors = errorData.details.map(d => d.msg).join(', ');
          throw new Error(`Validation Error: ${errors}`);
        }

        throw new Error(errorData.error || `API Error: ${response.statusText}`);
      }

      const data: APIResponse<Order[]> = await response.json();
      return (data.data || []).filter(order => order.status === 'active');
    } catch (error) {
      console.error('Error fetching orders:', error);
      return [];
    }
  },

  async fetchTradeHistory(address?: string, ticker?: string): Promise<TradeHistory[]> {
    try {
      let url = `${env.API_URL}/orders/history`;
      const params = new URLSearchParams();

      if (address) {
        params.append('userAddress', address.toLowerCase());
      }

      if (ticker) {
        params.append('ticker', ticker.toUpperCase());
      }

      if (params.toString()) {
        url += `?${params.toString()}`;
      }

      const response = await fetch(url, {
        headers: getHeaders()
      });

      if (!response.ok) {
        const errorData: APIError = await response.json().catch(() => ({
          error: response.statusText
        }));

        if (errorData.details) {
          const errors = errorData.details.map(d => d.msg).join(', ');
          throw new Error(`Validation Error: ${errors}`);
        }

        throw new Error(errorData.error || `API Error: ${response.statusText}`);
      }

      const data: APIResponse<TradeHistory[]> = await response.json();

      return (data.data || []).map(trade => ({
        ...trade,
        type: trade.from.toLowerCase() === address?.toLowerCase() ? 'sell' : 'buy'
      }));
    } catch (error) {
      console.error('Error fetching trade history:', error);
      return [];
    }
  },

  async fetchUserOrders(address: string): Promise<Order[]> {
    try {
      if (!address) {
        return [];
      }

      const response = await fetch(
        `${env.API_URL}/orders/user/${address.toLowerCase()}`,
        { headers: getHeaders() }
      );

      if (!response.ok) {
        const errorData: APIError = await response.json().catch(() => ({
          error: response.statusText
        }));

        if (errorData.details) {
          const errors = errorData.details.map(d => d.msg).join(', ');
          throw new Error(`Validation Error: ${errors}`);
        }

        throw new Error(errorData.error || `API Error: ${response.statusText}`);
      }

      const data: APIResponse<Order[]> = await response.json();
      return data.data || [];
    } catch (error) {
      console.error('Error fetching user orders:', error);
      return [];
    }
  },

  async createOrder(orderData: Omit<Order, 'status' | 'createdAt'>): Promise<Order> {
    try {
      const txData = JSON.parse(orderData.txJsonString);
      if (txData.inputs?.[0]) {
        txData.inputs[0].sighashType = 131;
      }
      const modifiedOrderData = {
        ...orderData,
        txJsonString: JSON.stringify(txData),
        psktData: JSON.stringify(txData)
      };

      const response = await fetch(`${env.API_URL}/orders`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(modifiedOrderData),
      });

      if (!response.ok) {
        const errorData: APIError = await response.json().catch(() => ({
          error: response.statusText
        }));
        if (errorData.details) {
          throw new Error(`Validation Error: ${errorData.details.map(d => d.msg).join(', ')}`);
        }
        throw new Error(errorData.error || 'Failed to create order');
      }

      return await response.json();
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  },

  async updateOrderStatus(id: string, status: 'completed' | 'canceled', buyer?: string): Promise<Order> {
    try {
      if (!id) {
        throw new Error('Order ID is required');
      }

      const response = await fetch(`${env.API_URL}/orders/${id}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({ status, buyer }),
      });

      if (!response.ok) {
        const errorData: APIError = await response.json().catch(() => ({
          error: response.statusText
        }));

        if (errorData.details) {
          const errors = errorData.details.map(d => d.msg).join(', ');
          throw new Error(`Validation Error: ${errors}`);
        }

        throw new Error(errorData.error || 'Failed to update order status');
      }

      return await response.json();
    } catch (error) {
      console.error('Error updating order status:', error);
      throw error;
    }
  },

  async deleteOrder(id: string): Promise<void> {
    try {
      if (!id) {
        throw new Error('Order ID is required');
      }

      const response = await fetch(`${env.API_URL}/orders/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        const errorData: APIError = await response.json().catch(() => ({
          error: response.statusText
        }));

        if (errorData.details) {
          const errors = errorData.details.map(d => d.msg).join(', ');
          throw new Error(`Validation Error: ${errors}`);
        }

        throw new Error(errorData.error || 'Failed to delete order');
      }
    } catch (error) {
      console.error('Error deleting order:', error);
      throw error;
    }
  }
};