import React, { useState, useEffect, useCallback } from 'react';
import { formatUnits, parseUnits, hexlify, toUtf8Bytes } from 'ethers';
import { getAssets, getAssetsByChain, getAggregateQuote, getChain } from '../pages/api/api';
import { Settings2, ArrowDownUp, Loader2, X } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Card, CardContent } from '../components/ui/card';
import { toast } from '../components/ui/use-toast';
import BigNumber from 'bignumber.js';

enum TxType {
  SIGN_TX,
  SEND_KASPA,
  SIGN_KRC20_DEPLOY,
  SIGN_KRC20_MINT,
  SIGN_KRC20_TRANSFER
}

interface ChangeSwapProps {
  connected: boolean;
  handleConnect: () => Promise<void>;
  walletAddress?: string;
  fixedToken?: string;
}

const KAS_TOKEN = {
  index: "18",
  symbol: "KAS",
  decimals: 8,
  address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
  name: "Kaspa"
};

const KAS_MINTER = 'kaspa:qpgmt2dn8wcqf0436n0kueap7yx82n7raurlj6aqjc3t3wm9y5ssqtg9e4lsm';
const KRC20_MINTER = 'kaspa:qz9cqmddjppjyth8rngevfs767m5nvm0480nlgs5ve8d6aegv4g9xzu2tgg0u';
const SLIPPAGE_OPTIONS = [
  { value: "0.5", label: "0.5%" },
  { value: "1", label: "1%" },
  { value: "2", label: "2%" },
  { value: "5", label: "5%" },
];

const getTransactionId = (txHash: any): string => {
  try {
    if (typeof txHash === 'string') {
      const idMatch = txHash.match(/"id":"(.*?)"/);
      if (idMatch && idMatch[1]) {
        return idMatch[1];
      }

      const revealMatch = txHash.match(/"revealId":"(.*?)"/);
      if (revealMatch && revealMatch[1]) {
        return revealMatch[1];
      }
    }
  } catch (error) {
    console.error("Error extracting transaction ID:", error);
  }

  return '';
};

const ChangeSwap: React.FC<ChangeSwapProps> = ({
  connected,
  handleConnect,
  walletAddress,
  fixedToken
}) => {
  const [tokens, setTokens] = useState<any[]>([]);
  const [fromToken, setFromToken] = useState(KAS_TOKEN);
  const [toToken, setToToken] = useState<any>(null);
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [quoteData, setQuoteData] = useState<any>(null);
  const [error, setError] = useState('');
  const [slippage, setSlippage] = useState('5');
  const [customSlippage, setCustomSlippage] = useState('');
  const [showSlippagePanel, setShowSlippagePanel] = useState(false);
  const [slippageError, setSlippageError] = useState('');
  const [extra, setExtra] = useState('');
  const [toExpectAmount, setToExpectAmount] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [supportChainList, setSupportChainList] = useState<any[]>([]);
  const [tokenIconUrl, setTokenIconUrl] = useState<string>('');
  const [userBalances, setUserBalances] = useState<{
    kas: number;
    tokens: { [key: string]: number };
  }>({
    kas: 0,
    tokens: {}
  });

  useEffect(() => {
    const fetchBalances = async () => {
      if (!walletAddress) return;

      try {
        const kasResponse = await fetch(`https://api.kaspa.org/addresses/${walletAddress}/balance`);
        const kasData = await kasResponse.json();
        const kasBalance = kasData.balance / 1e8;
        const tokenResponse = await fetch(`https://api.kasplex.org/v1/krc20/address/${walletAddress}/tokenlist`);
        const tokenData = await tokenResponse.json();
        const tokenBalances: { [key: string]: number } = {};

        if (tokenData.result) {
          tokenData.result.forEach((token: any) => {
            tokenBalances[token.tick] = Number(token.balance) / Math.pow(10, Number(token.dec));
          });
        }

        setUserBalances({
          kas: kasBalance,
          tokens: tokenBalances
        });
      } catch (error) {
        console.error('Error fetching balances:', error);
      }
    };

    fetchBalances();
  }, [walletAddress]);

  const checkBalance = (amount: string, token: any): boolean => {
    if (!amount) return true;

    const numAmount = Number(amount);
    if (isNaN(numAmount)) return false;

    if (token.symbol === 'KAS') {
      const withBuffer = numAmount + 0.1;
      if (withBuffer > userBalances.kas) {
        setError(`Insufficient KAS balance. You have ${userBalances.kas.toFixed(4)} KAS`);
        return false;
      }
    } else {
      const tokenBalance = userBalances.tokens[token.symbol] || 0;
      if (numAmount > tokenBalance) {
        setError(`Insufficient ${token.symbol} balance. You have ${tokenBalance.toFixed(4)} ${token.symbol}`);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const fetchTokenData = async () => {
      if (!fixedToken) return;
      try {
        const response = await fetch('https://tokentable-server.vercel.app/api');
        const data = await response.json();
        const tokenData = data.results
          .flatMap((item: any) => item.data?.results || [item])
          .find((token: any) => token.ticker === fixedToken);
        if (tokenData?.iconUrl) {
          setTokenIconUrl(tokenData.iconUrl);
        }
      } catch (error) {
        console.error('Error fetching token data:', error);
      }
    };
    fetchTokenData();
  }, [fixedToken]);

  useEffect(() => {
    if (fixedToken && !toToken) {
      const matchingToken = tokens.find(t => t.symbol === fixedToken);
      if (matchingToken) {
        setToToken(matchingToken);
      }
    }
  }, [fixedToken, tokens, toToken]);

  const handleSlippageChange = (value: string, isCustom = false) => {
    if (isCustom) {
      setCustomSlippage(value);
      if (value === '') {
        setSlippageError('');
        return;
      }
      const numValue = parseFloat(value);
      if (isNaN(numValue)) {
        setSlippageError('Please enter a valid number');
      } else if (numValue <= 0) {
        setSlippageError('Slippage must be greater than 0');
      } else if (numValue >= 99) {
        setSlippageError('Slippage too high');
      } else {
        setSlippageError('');
        setSlippage(value);
      }
    } else {
      setSlippage(value);
      setCustomSlippage('');
      setSlippageError('');
    }
  };

  const TokenSelector = ({ position }: { position: 'from' | 'to' }) => {
    const currentToken = position === 'from' ? fromToken : toToken;
    const isKAS = currentToken?.symbol === 'KAS';
    return (
      <div className="flex items-center gap-1 bg-accent p-1 rounded-lg min-w-[90px]">
        <div className="w-5 h-5 rounded-full overflow-hidden ring-1 ring-border">
          <img
            src={isKAS ? "/kas.png" : (tokenIconUrl || '/token-icon.svg')}
            alt={isKAS ? "KAS" : fixedToken}
            className="w-full h-full object-cover"
            onError={(e) => {
              (e.target as HTMLImageElement).src = '/token-icon.svg';
            }}
          />
        </div>
        <span className="text-sm font-medium">{isKAS ? "KAS" : fixedToken}</span>
      </div>
    );
  };

  useEffect(() => {
    const getPublicKey = async () => {
      if (connected) {
        try {
          const kasware = (window as any).kasware;
          if (kasware) {
            const newPublicKey = await kasware.getPublicKey();
            setPublicKey(newPublicKey);
          }
        } catch (error) {
          console.error('Failed to get public key:', error);
        }
      } else {
        setPublicKey('');
      }
    };
    getPublicKey();
  }, [connected]);

  useEffect(() => {
    const loadTokens = async () => {
      try {
        setLoading(true);
        const assets = await getAssets();
        const kasAssets = await getAssetsByChain('KAS');
        const formattedTokens = assets
          .map((token: any) => {
            const kasAsset = kasAssets?.find((ka: any) => ka.symbol === token.symbol);
            return {
              index: token.index.toString(),
              symbol: token.symbol,
              decimals: kasAsset?.decimals || token.contracts?.KAS?.decimals || 8,
              address: token.contracts?.KAS?.address || token.symbol,
              name: token.name,
              contracts: token.contracts
            };
          })
          .filter((token: any) => token.symbol !== 'KAS');
        setTokens(formattedTokens);
      } catch (error) {
        console.error('Failed to load tokens:', error);
        setError('Failed to load tokens');
      } finally {
        setLoading(false);
      }
    };

    loadTokens();
  }, []);

  useEffect(() => {
    const loadChains = async () => {
      try {
        const chainsResponse = await getChain();
        if (chainsResponse?.code === 0) {
          setSupportChainList(chainsResponse.data.list || []);
        }
      } catch (error) {
        console.error('Failed to load chains:', error);
      }
    };
    loadChains();
  }, []);

  const getQuote = useCallback(async () => {
    if (!fromToken || !toToken || !fromAmount || !connected) return;
    try {
      setQuoteLoading(true);
      setError('');
      const quoteParams = {
        fromAmount: parseUnits(fromAmount, fromToken.decimals).toString(),
        fromTokenAddress: fromToken.address,
        fromDecimal: fromToken.decimals.toString(),
        fromChain: 'KAS',
        toTokenAddress: toToken.address,
        toDecimal: toToken.decimals.toString(),
        toChain: 'KAS',
        channelFeeRate: '0',
      };
      const quoteResult = await getAggregateQuote(quoteParams);
      if (quoteResult.code === 0) {
        const { chain, chainDecimal, outAmount, serviceFee, gasFee } = quoteResult.data;
        const executionChainObj = supportChainList.find((item) => item.network === chain);
        let chainNickname = 'FSN';
        if (executionChainObj) {
          chainNickname = executionChainObj.nickName;
        }

        setToAmount(formatUnits(outAmount, toToken.decimals));

        const receiveAmount = BigInt(outAmount) - BigInt(serviceFee) - BigInt(gasFee);
        if (receiveAmount <= BigInt(0)) {
          setError('Amount too small to cover fees');
          setToAmount('0');
          setQuoteData(null);
          return;
        }
        const receiveAmountHr = formatUnits(receiveAmount, toToken.decimals);
        const receiveAmountForExtra = parseUnits(receiveAmountHr, toToken.decimals).toString();
        const miniAmount = new BigNumber(receiveAmountHr)
          .multipliedBy(new BigNumber(1 - (Number(slippage) * 0.01)))
          .toFixed(toToken.decimals);
        const miniAmountForExtra = parseUnits(miniAmount, toToken.decimals).toString();
        const extraData = `1_${receiveAmountForExtra};2_0;3_2;4_${miniAmountForExtra};5_${chainNickname}`;
        setExtra(extraData);
        setToExpectAmount(receiveAmountForExtra);
        setQuoteData(quoteResult.data);
      } else {
        throw new Error(quoteResult.message || 'Failed to get quote');
      }
    } catch (err: any) {
      console.error('Error getting quote:', err);
      setError(err.message);
      setQuoteData(null);
    } finally {
      setQuoteLoading(false);
    }
  }, [fromToken, toToken, fromAmount, connected, slippage, supportChainList]);

  useEffect(() => {
    if (fromAmount && fromToken && toToken && connected) {
      const timeoutId = setTimeout(() => {
        getQuote();
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [fromAmount, fromToken, toToken, connected, getQuote]);

  const handleSwap = async () => {
    if (!connected || !walletAddress) {
      try {
        await handleConnect();
        return;
      } catch (err: any) {
        setError(err.message);
        return;
      }
    }
    if (!publicKey) {
      setError('Public key not available. Please try reconnecting your wallet.');
      return;
    }
    if (!fromAmount || !toToken) {
      setError('Please enter amount and select tokens');
      return;
    }

    if (!checkBalance(fromAmount, fromToken)) {
      return;
    }

    setLoading(true);
    setError('');
    try {
      const kasware = (window as any).kasware;
      if (!kasware) throw new Error('KasWare wallet not found');
      const parsedAmount = parseUnits(fromAmount, fromToken.decimals).toString();
      let txHash;
      if (fromToken.symbol === 'KAS') {
        txHash = await kasware.sendKaspa(KAS_MINTER, parsedAmount, {
          priorityFee: 10000
        });
        console.log('KAS tx hash:', txHash);
      } else {
        const txObject = {
          p: 'KRC-20',
          op: 'transfer',
          tick: fromToken.symbol,
          amt: parsedAmount,
          to: KRC20_MINTER
        };
        txHash = await kasware.signKRC20Transaction(
          JSON.stringify(txObject),
          TxType.SIGN_KRC20_TRANSFER,
          KRC20_MINTER,
          0.1
        );
        console.log('Token tx hash:', txHash);
      }

      const transactionId = getTransactionId(txHash);
      console.log('Extracted transaction ID:', transactionId);
      const raw = `${transactionId}_KAS_${fromToken.index}_${parsedAmount}_KAS_${toToken.index}_${toExpectAmount}_${walletAddress}`;
      const signature = await kasware.signMessage(raw);
      const sourceCertsObj = {
        fromAmount: parsedAmount,
        fromIndex: fromToken.index.toString(),
        fromChain: 'KAS',
        fromAddr: walletAddress,
        certHash: transactionId,
        fromPublicKey: publicKey,
        fromDecimals: fromToken.decimals.toString(),
        toDecimals: toToken.decimals.toString(),
        signature
      };
      const sourceCertsHex = hexlify(toUtf8Bytes(JSON.stringify(sourceCertsObj))).substring(2);
      const slippageNumBI = new BigNumber(slippage);
      const formatSlippage = slippageNumBI.multipliedBy(new BigNumber(100)).toFixed(0);
      const params = {
        sourceCerts: sourceCertsHex,
        orderType: "2",
        toIndex: toToken.index.toString(),
        toChain: "KAS",
        toAddr: walletAddress,
        slippage: formatSlippage,
        fromDecimals: fromToken.decimals.toString(),
        toDecimals: toToken.decimals.toString(),
        execStrategy: "",
        extra,
        triggerPrice: "0",
        timeout: "0",
        channel: "burtsworld",
      };
      const response = await fetch('https://api2.chainge.finance/v1/submitOrder', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Address": walletAddress,
          "PublicKey": publicKey,
          "Chain": 'KAS',
          "Signature": signature
        },
        body: JSON.stringify(params)
      });
      const result = await response.json();
      if (result.code === 0) {
        try {
          const isSellingKas = fromToken.symbol === 'KAS';
          const saveResponse = await fetch('https://change-tx.vercel.app/api/saveTransaction', {  
            method: 'POST',
            mode: 'cors', 
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json', 
            },
            body: JSON.stringify({
              walletAddress,
              transactionType: isSellingKas ? 'sellKAS' : 'sellToken',
              kasAmount: isSellingKas ? fromAmount : toAmount,
              tokenAmount: isSellingKas ? toAmount : fromAmount,
              tokenSymbol: isSellingKas ? toToken.symbol : fromToken.symbol,
              timestamp: new Date().toISOString(),
              transactionId
            }),
          });
      
          const saveResult = await saveResponse.json();
          if (!saveResponse.ok) {
            console.error('Failed to save transaction to database', saveResult);
          } else {
            console.log('Transaction saved successfully', saveResult);
          }
        } catch (dbError) {
          console.error('Error saving to database:', dbError);
        }
      
        toast({
          title: "Success",
          description: `Successfully initiated swap of ${fromAmount} ${fromToken.symbol} to ${toToken.symbol}`,
        });
        setFromAmount('');
        setToAmount('');
        setQuoteData(null);
      } else {
        throw new Error(result.message || 'Swap failed');
      }
    } catch (err: any) {
      console.error('Swap error:', err);
      setError(err.message || 'Failed to execute swap');
      toast({
        title: "Error",
        description: err.message || 'Failed to execute swap',
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const switchTokens = () => {
    if (toToken) {
      const temp = fromToken;
      setFromToken(toToken);
      setToToken(temp);
      setFromAmount(toAmount);
      setToAmount('');
      setQuoteData(null);
    }
  };

  return (
    <Card className="w-full">
      <CardContent className="p-1">
        <div className="relative">
          <div className="flex justify-between items-center mb-0">
            <h3 className="text-base font-semibold">Chainge Swap</h3>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setShowSlippagePanel(!showSlippagePanel)}
              className="h-8 w-8 p-0"
            >
              <Settings2 className="h-4 w-4" />
            </Button>
          </div>

          {showSlippagePanel && (
            <div className="absolute top-12 left-0 right-0 bg-background border rounded-lg p-4 shadow-lg z-10">
              <div className="flex justify-between items-center mb-4">
                <h4 className="font-medium">Slippage</h4>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => setShowSlippagePanel(false)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>

              <div className="grid grid-cols-4 gap-2 mb-4">
                {SLIPPAGE_OPTIONS.map((option) => (
                  <Button
                    key={option.value}
                    variant={slippage === option.value && !customSlippage ? "default" : "outline"}
                    className="w-full"
                    onClick={() => handleSlippageChange(option.value)}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>

              <div className="space-y-2">
                <Label>Custom slippage</Label>
                <div className="flex items-center gap-2">
                  <Input
                    type="number"
                    placeholder="Custom"
                    value={customSlippage}
                    onChange={(e) => handleSlippageChange(e.target.value, true)}
                    className={slippageError ? "border-red-500" : ""}
                  />
                  <span className="text-sm">%</span>
                </div>
                {slippageError && <p className="text-sm text-red-500">{slippageError}</p>}
              </div>
            </div>
          )}
        </div>

        <div className="space-y-1">
          <div className="bg-accent/50 p-2 rounded-lg">
            <div className="flex justify-between mb-1">
              <span className="text-xs text-muted-foreground">Sell</span>
            </div>
            <div className="flex items-center gap-1">
              <Input
                type="number"
                placeholder="0.0"
                value={fromAmount}
                onChange={(e) => setFromAmount(e.target.value)}
                className="border-0 bg-transparent text-lg p-0"
              />
              <TokenSelector position="from" />
            </div>
          </div>

          <div className="flex justify-center -my-1">
            <Button
              variant="ghost"
              size="sm"
              onClick={switchTokens}
              className="bg-background rounded-full h-8 w-8 p-0 shadow-md"
            >
              <ArrowDownUp className="h-3 w-3" />
            </Button>
          </div>

          <div className="bg-accent/50 p-2 rounded-lg">
            <div className="flex justify-between mb-1">
              <span className="text-xs text-muted-foreground">To</span>
            </div>
            <div className="flex items-center gap-1">
              <Input
                type="number"
                placeholder="0.0"
                value={toAmount}
                readOnly
                className="border-0 bg-transparent text-lg p-0"
              />
              <TokenSelector position="to" />
            </div>
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          {quoteData && (
            <div className="bg-accent/50 p-3 rounded-md space-y-2 text-sm">
              <div className="flex justify-between">
                <span className="text-muted-foreground">Slippage Tolerance</span>
                <span>{slippage}%</span>
              </div>
              <div className="flex justify-between">
                <span className="text-muted-foreground">Minimum Received</span>
                <span>
                  {formatUnits(
                    BigInt(quoteData.outAmount) - BigInt(quoteData.serviceFee || '0') - BigInt(quoteData.gasFee),
                    toToken?.decimals || quoteData.chainDecimal
                  )} {toToken?.symbol}
                </span>
              </div>
            </div>
          )}

          <Button
            className="w-full"
            onClick={handleSwap}
            disabled={loading || quoteLoading || error === 'Amount too small to cover fees' || !fromAmount || !toToken || !connected}
          >
            {(loading || quoteLoading) && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {!connected ? 'Connect' :
              loading ? 'Loading...' :
                quoteLoading ? 'Getting quote...' :
                  error === 'Amount too small to cover fees' ? 'Amount too small' :
                    !fromAmount ? 'Enter amount' :
                      !toToken ? 'Select token' :
                        'Swap'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ChangeSwap;